import imageSVG_1 from "../../../../src/content/demographics/exness_Demographic_1.svg";
import imageSVG_2 from "../../../../src/content/demographics/exness_Demographic_2.svg";
import imageSVG_3 from "../../../../src/content/demographics/exness_Demographic_3.svg";
import imageSVG_4 from "../../../../src/content/demographics/exness_Demographic_4.svg";
import imageSVG_5 from "../../../../src/content/demographics/exness_Demographic_5.svg";
import imageSVG_6 from "../../../../src/content/demographics/exness_Demographic_6.svg";
import imageSVG_7 from "../../../../src/content/demographics/exness_Demographic_7.svg";
import imageSVG_8 from "../../../../src/content/demographics/exness_Demographic_8.svg";
import * as React from 'react';
export default {
  imageSVG_1,
  imageSVG_2,
  imageSVG_3,
  imageSVG_4,
  imageSVG_5,
  imageSVG_6,
  imageSVG_7,
  imageSVG_8,
  React
};